import * as React from 'react';
import GbCard from '@nc-gb-sds/card/dist/react';
import GbButton from '@nc-gb-sds/button/dist/react';
import './CardComponent.scss';

const CardComponent = ({ className, imageSrc, category, title, description, buttonRight }) => (
    <div className={className}>
        <GbCard
            image={<img src={imageSrc} alt="Illustration" />}>
            <p className="gb-mb-1 gb-text-uppercase gb-text-smallest">{category}</p>
            <h2 className="gb-h4">{title}</h2>
            <p>
                {description}
            </p>
            <div className='gb-mt-2'>
              <div className="gb-text-right gb-mt-2">
                  <GbButton size="sm" skin="gray" skinModifier="muted">{buttonRight}</GbButton>
              </div>
            </div>
        </GbCard>
    </div>
);

export default CardComponent;