import * as React from 'react';
import { useState } from 'react';
import { GbAccordion, GbAccordionItem } from '@nc-gb-sds/accordion/dist/react';
import GbIcon from '@nc-gb-sds/icons/dist/react';
import './AccordianComponent.scss';

const FAQ = [
    {
        id: 1,
        open: false,
        title: 'Privacy and Security',
        description: '',
        children:
            'Your website security and privacy comes first at Namecheap, and we will always support the rights of individuals and consumers online. It\'s our mission to keep the Internet open, free, and safe for everyone.',
    },
    {
        id: 2,
        open: false,
        title: 'Customer Service',
        description: '',
        children:
            'Boost your business with industry-premium products and services, at prices that won\'t break your budget. If it doesn\'t provide you with a better Internet experience, we simply don\'t offer it.',
    },
    {
      id: 3,
      open: false,
      title: 'Is Site Maker easy to use?',
      description: '',
      children:
          'Site Maker has been designed especially for those with no experience of building a website. The unique build process, and easy-to-use blocks, means anyone can get a site up and running in no time.',
  },
  {
    id: 4,
    open: false,
    title: 'Are Visual\'s tools free to use?',
    description: '',
    children:
        'Logo Maker is completely free. You can make unlimited logos, and download as many variations as you want for each.'
        +  'Site Maker has free trial options which don\'t require any credit card information to use. After your trial expires, you will need to choose one of our plans to keep your site online.'
        + 'To design a card on Card Maker is free initially, but in order to download the PDF, or get physical copies, you will need to choose one of our reasonably-priced payment options.',
  },
];

const AccordianComponent = () => {
    const [useList, setList] = useState(FAQ);

    const handleChange = (id) => {
        setList(
            useList.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        open: !item.open,
                    };
                }
                return item;
            }),
        );
    };

    return (
        <GbAccordion>
            { useList.map((item) => (
                <GbAccordionItem
                    key={item.id}
                    className="gb-accordion-custom"
                    media={<GbIcon name="question-circled-filled" />}
                    title={item.title}
                    description={item.description}
                    isOpened={item.open}
                    onClick={() => handleChange(item.id)}>
                    <p>{ item.children }</p>
                </GbAccordionItem>
            )) }
        </GbAccordion>
    );
};

export default AccordianComponent;
